.gerry {
  display: flex;
  align-items: center;
  padding: 100px;
  background-color: lightgray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.gerry-title {
  font-size: xx-large;
  margin-left: 100px;
}

.gerry-text {
  margin-left: 100px;
  line-height: 1.6;
}

.gerry-image {
  height: 400px;
  max-width: 80%;
}

@media screen and (max-width: 576px) {
  .gerry {
    padding: 30px;
    flex-direction: column;
  }

  .gerry-title {
    margin-left: 0px;
    text-align: center;
  }
  
  .gerry-text {
    margin-left: 0px;
    font-size: 16px;
  }
}