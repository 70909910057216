.start {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.start-logo {
  height: 60%;
  max-width: 80%;
}