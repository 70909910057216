.galerie {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px;
}

.galerie-title {
  font-size: xx-large;
  text-align: center;
}

.galerie-satz {
  text-align: center;
}

.galerie-bilder {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.galerie-bilder * {
  max-height: 300px;
}

.accordion {
  width: 100%;
}

.card-header {
  background-color: white !important;
}

@media screen and (max-width: 576px) {
  .galerie {
    padding: 30px;
    flex-direction: column;
  }

  .galerie-bilder * {
    max-width: 80vw;
  }

  .galerie-satz {
    font-size: 16px;
  }
}