.kontaktformular {
  padding: 100px;
  text-align: center;
  background-color: lightgray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.kontaktformular-title {
  font-size: xx-large;
  text-align: center;
}

.feld {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

Input, TextArea {
  width: 400px;
}

.kontaktformular-button {
  padding: 15px;
  margin-bottom: 30px;
  width: 400px;
  font-size: large;
  color: white;
  background-color: #922A2A;
  border: 0px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .kontaktformular {
    padding: 30px;
  }

  Input, TextArea, .kontaktformular-button {
    width: 80vw;
  }
}