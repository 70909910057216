.navigation {
  position: sticky;
  z-index: 100000;
  align-self: flex-start;
  top: 0;
  overflow-y: auto;
  height: 80px;
  width: 100%;
  background-color: #922A2A;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.normal {
  color: white;
  font-size: 21px;
  text-decoration: none;
  border-bottom: solid #922A2A;
}

.normal:hover {
  color: lightgray;
}

@media screen and (max-width: 576px) {
  .normal {
    font-size: 16px;
  }
}