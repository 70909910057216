.navigationMobile {
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 80px;
  width: 100%;
  background-color: #922A2A;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.burger-menu {
  cursor: pointer;
  margin-right: 20px;
}

.bars, .xmark {
  width: 40px;
}

.navigationMobile-open {
  position: fixed;
  top: 80px;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #922A2A;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.navigationMobile-open * {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
}