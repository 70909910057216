.termine {
  display: flex;
  flex-direction: column;
  padding: 100px;
  background-color: lightgray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.termine-satz {
  text-align: center;
}

.termine-title1, .termine-title2 {
  font-size: xx-large;
  text-align: center;
}

.termine-title2 {
  margin-top: 100px;
}

.termine-kacheln {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 576px) {
  .termine {
    padding: 30px;
    flex-direction: column;
  }

  .termine-satz {
    font-size: 16px;
  }
}