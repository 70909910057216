.footer {
  width: 100%;
  background-color: #922A2A;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-oben {
  width: 60vw;
  display: flex;
  justify-content: space-between;
  column-gap: 100px;
  border-bottom: solid 1px white;
  padding-bottom: 25px;
}

.footer-unten {
  width: 60vw;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.footer-kontakt-container {
  display: flex;
  column-gap: 25px;
  align-items: center;
  font-size: 16px;
  margin-bottom: 25px;
}

.footer-kontakt-container > img {
  height: 25px;
}

.facebook-icon {
  height: 50px;
  margin-right: 20px;
}

.instagram-icon {
  height: 50px;
}

.impressum-link {
  font-size: 16px;
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 576px) {
  .footer-oben {
    width: 80vw;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .footer-unten {
    width: 80vw;
    display: unset;
  }
}