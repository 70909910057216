.band {
  display: flex;
  align-items: center;
  padding: 100px;
}

.band-title {
  font-size: xx-large;
  text-align: center;
}

.band-text {
  line-height: 1.6;
}

.microphone {
  height: 400px;
  max-width: 80%;
}

@media screen and (max-width: 576px) {
  .band {
    padding: 30px;
  }

  .band-title {
    text-align: center;
  }

  .band-text {
    font-size: 16px;
  }
}