.manni {
  display: flex;
  align-items: center;
  padding: 100px;
}

.manni-title {
  font-size: xx-large;
  margin-left: 100px;
}

.manni-text {
  margin-left: 100px;
  line-height: 1.6;
}

.manni-image {
  height: 400px;
  max-width: 80%;
}

@media screen and (max-width: 576px) {
  .manni {
    padding: 30px;
    flex-direction: column;
  }

  .manni-title {
    margin-left: 0px;
    text-align: center;
  }
  
  .manni-text {
    margin-left: 0px;
    font-size: 16px;
  }
}