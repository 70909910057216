.terminkarte {
  width: 400px;
  box-shadow: 0 0 5px 2px grey;
  background-color: black;
  color: white;
}

.terminkarte-bild {
  display: flex;
  height: 200px;
  background-color: #922A2A;
  align-items: center;
  justify-content: center;
}

.terminkarte-bild > img {
  max-height: 200px;
  max-width: 100%;
}

.terminkarte-daten {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "monat uhrzeit"
    "tag eventname"
    "jahr adresse";
  margin: 20px;
  row-gap: 5px;
  column-gap: 20px;
}

.terminkarte-monat {
  grid-area: monat;
  font-size: 17px;
  font-weight: bold;
  color: #922A2A;
  text-align: right;
}

.terminkarte-uhrzeit {
  grid-area: uhrzeit;
  font-size: 17px;
}

.terminkarte-tag {
  grid-area: tag;
  font-size: 21px;
  text-align: right;
}

.terminkarte-eventname {
  grid-area: eventname;
  font-size: 21px;
  font-weight: bold;
}

.terminkarte-jahr {
  grid-area: jahr;
  font-size: 17px;
  text-align: right;
}

.terminkarte-adresse {
  grid-area: adresse;
  font-size: 19px;
}

@media screen and (max-width: 576px) {
  .terminkarte {
    width: 100vw;
    max-width: 350px;
  }
}